#wrapper {
  position: absolute;
  width: 100%;
  height: 50%;
  /*border: 1px solid black;*/
  /*display: flex;*/
  align-items: center ;
  justify-content: center;
  top: 0px;
}
#tracker {
  position: absolute;
  width: 100%;
  height: 100%;
  /*border: 1px solid black;*/
  /*display: flex;*/
  align-items: center ;
  justify-content: center;
  top: 300px;
}

div.CompSelect {
  position: relative;
  width: 100%;
  height: 200px;
  /*border: 1px solid black;*/
  display: flex;
  align-items: center ;
  justify-content: center;
  top: 0px;
}

#audioPlayer {
  width: 100%;
  height: 200px;
}

#contextStarter {
  width: 100%;
  height: 100px;
  top: 200px;
  /*border: 1px solid black;*/
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DDD;
}

#permission {
  /*position: absolute;*/
  width: 100%;
  height: 200px;
  top: 100px;
  /*border: 1px solid black;*/
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  flex-direction: column;
  text-align: center;
}

#perm {
  background-color: #DDD;
  border: 1px solid #999;
  color: #999;
  display: flex;
}


#button {
  height: 20px;
  width: 100px;
}

#ding {
  width: 100%;
  height: 300px;
  background-color: grey;
}

table {
  text-align: center;
}





div.centreren {
  text-align: center;
}